import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { SideBarProps } from './SideBar.model'
import styles from './SideBar.module.scss'

export const SideBar: React.FC<SideBarProps> = (props) => {
  const { show, setOpen, children } = props

  return (
    <div
      className={styles['sidebar']}
      style={{
        display: show ? 'flex' : 'none'
      }}
    >
      <button className={styles['button-close']} onClick={() => setOpen(false)}>
        <CloseIcon fontSize="large" style={{ color: 'white' }} />
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}
      >
        {children}
      </div>
    </div>
  )
}
