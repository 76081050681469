import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IconType } from 'components/Icons/Icon.model'
import { Icon } from 'components/Icons/Icon.component'
import { SurveyStatusState } from 'components/Modals/InnerModal/InnerModal.model'
import styles from './SideBar/SideBar.module.scss'
import { SideBar } from './SideBar/SideBar.component'
import HomeIcon from '@mui/icons-material/Home'
import FlagIcon from '@mui/icons-material/Flag'
import store from 'store/store'
import {
  InnerModal,
  ExitDashboardConfirm
} from 'components/Modals/InnerModal/InnerModal.component'

const modalStyle = {
  position: 'absolute',
  top: '50dvh',
  left: '50dvw',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const formatDate = (date: Date | undefined): string => {
  if (!date) return ''

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }

  return date.toLocaleString('en-AU', options)
}

export const RASurveyControls = () => {
  const [open, setOpen] = useState(false)
  const [modalState, setModalState] = useState<SurveyStatusState>('')

  const [show, setShow] = useState(false)
  const [exitDashboard, setExitDashboard] = useState(false)

  const toggleModal = () => {
    setOpen((isOpen) => !isOpen)
    if (open) {
      setExitDashboard(false)
    }
  }

  const modalClose = () => {
    setModalState('')
    setExitDashboard(false)
  }

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setOpen(true)
    setModalState('')
    setShow(true)
    setExitDashboard(false)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length === 3) {
      setOpen(true)
      setModalState('')
      setShow(true)
      setExitDashboard(false)
    }
  }

  const handleToDashboard = () => {
    setShow(false)
    setExitDashboard(true)
  }

  const globalState = store.getState()

  const gender = globalState.surveyData?.['participant-gender'] ?? 'N/A'
  const age = globalState.surveyData?.['age'] ?? 'N/A'

  const date = formatDate(globalState.surveyMetaData?.startTime)
  const surveyStatus = globalState.surveyMetaData?.status ?? 'in progress'
  const surveyId = globalState?.surveyMetaData?.slk ?? 'undefined'

  return (
    <>
      <div
        style={{
          minHeight: '100%',
          width: '100%'
        }}
        onContextMenu={handleRightClick}
        onTouchStart={handleTouchStart}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              width: '100%'
            }}
          >
            <SideBar setOpen={setOpen} show={show}>
              <button
                onClick={() => {
                  console.log('Log for SLK', globalState.surveyMetaData?.slk)
                  console.log(
                    'Started at',
                    globalState?.surveyMetaData?.startTime
                  )
                  console.log('Survey State:', globalState)
                }}
              >
                Log to console
              </button>
              <button
                className={styles['button-general']}
                onClick={() => setShow((s) => !s)}
              >
                <FlagIcon fontSize="large" style={{ color: 'white' }} />
                <span>Flag Survey</span>
              </button>
              <button
                className={styles['button-general']}
                onClick={handleToDashboard}
              >
                <HomeIcon fontSize="large" style={{ color: 'white' }} />
                <span>Dashboard</span>
              </button>
            </SideBar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: show ? '80%' : '100%',
                placeContent: 'center'
              }}
            >
              {/* backdrop */}

              {!show && !exitDashboard && (
                <div
                  style={{
                    width: '50%',
                    height: '50%',
                    background: 'white',
                    padding: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      background: '#028B83',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <button
                      onClick={() => {
                        toggleModal()
                        modalClose()
                        setShow(false)
                      }}
                      style={{
                        padding: '20px',
                        background: 'none',
                        border: 'none'
                      }}
                    >
                      <CloseIcon fontSize="large" style={{ color: 'white' }} />
                    </button>
                    <span style={{ color: 'white' }}>
                      {surveyStatus} Survey: {surveyId}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      height: '100%'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '20px'
                      }}
                    >
                      <div style={{ width: '20%', marginRight: '10%' }}>
                        <div>Gender:</div>
                        <div>Age:</div>
                        <div>Date:</div>
                        <div>Status:</div>
                      </div>
                      <div style={{ width: '70%' }}>
                        <div>{gender}</div>
                        <div>{age}</div>
                        <div>{date}</div>
                        <div>{surveyStatus}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}
                    >
                      <span>Flag this survey:</span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'center',
                          marginTop: 10,
                          gap: 10
                        }}
                      >
                        <button
                          style={{
                            background: '#d3d3d3',
                            height: '14dvh',
                            width: '150px',
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setModalState('deletion')}
                        >
                          <Icon icon={IconType.Delete} />
                          <span>{'Deletion'}</span>
                        </button>
                        <button
                          style={{
                            background: '#d3d3d3',
                            height: '14dvh',
                            width: '150px',
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setModalState('incomplete')}
                        >
                          <Icon icon={IconType.Incomplete} />
                          <span>{'Incomplete'}</span>
                        </button>
                        <button
                          style={{
                            background: '#d3d3d3',
                            height: '14dvh',
                            width: '150px',
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setModalState('technical')}
                        >
                          <Icon icon={IconType.Technical} />
                          <span>{'Technical'}</span>
                        </button>
                        <InnerModal
                          modalState={modalState}
                          modalClose={modalClose}
                          modalCloseParent={() => setOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {exitDashboard && !show && (
                <ExitDashboardConfirm
                  modalState={modalState}
                  modalClose={modalClose}
                  modalCloseParent={() => {
                    setOpen(false)
                    setExitDashboard(false)
                  }}
                />
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
