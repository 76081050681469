/**
 * The Redux store fields required to be defined so the app is functional in an offline
 * setting (when logging in as an RA).
 */
export const REDUX_STORE_OFFLINE_FIELDS = [
  'emailSuggestions',
  'service',
  'geoLocations',
  'grogShops',
  'assetUrls',
  'slkList'
] as const

/**
 * The Redux store fields required to be defined so the app is functional in an offline
 * setting (when using Demo Mode).
 */
export const REDUX_STORE_DEMO_OFFLINE_FIELDS = [
  'demoService',
  'demoGeoLocations',
  'demoGrogShops',
  'demoAssetUrls'
] as const

/**
 * The whitelist for fields which should be persisted in the Redux store when reloading
 * the app.
 */
export const REDUX_STORE_PERSIST_WHITELIST = [
  ...REDUX_STORE_OFFLINE_FIELDS,
  ...REDUX_STORE_DEMO_OFFLINE_FIELDS,
  'surveyResponses'
] as const
