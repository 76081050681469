import { genericObject } from 'screens/survey/type'
import { TargetGender } from 'shared/utils/raDashboardCalculation/raDashboardCalculation.model'
import { SurveyResponse } from 'store/type'

export enum Operator {
  'EQUAL_TO' = 'EQUAL_TO',
  'NOT_EQUAL_TO' = 'NOT_EQUAL_TO',
  'GREATER_THAN' = 'GREATER_THAN',
  'LESS_THAN' = 'LESS_THAN',
  'GREATER_AND_EQUAL' = 'GREATER_AND_EQUAL',
  'LESS_AND_EQUAL' = 'LESS_AND_EQUAL',
  'CONTAIN' = 'CONTAIN',
  'NOT_CONTAIN' = 'NOT_CONTAIN',
  'LONGER_THAN' = 'LONGER_THAN',
  'SHORTER_THAN' = 'SHORTER_THAN'
}

// Login Data Model
export interface UseAPIHookLoginDataModel {
  Username: string
  Pin: string
  demoMode?: boolean
}

export interface LoginAPIResponseModel {
  data?: string | null
  message?: string | null
  success?: boolean
}

export interface LoginAPIResponseDataModel {
  iat?: number
  serviceId: string
  id: string
  profilePhoto?: string | null
  firstName?: string | null
  lastName?: string | null
  username: string
  email: string
  phone?: string | null
}

export interface SubmissionAPIResponseResultModel {
  completedSubmissions: SurveyResponse[]
  toBeContinueSubmissions: SurveyResponse[]
}

export interface SubmissionAPIResponseModel {
  success: boolean
  result?: SubmissionAPIResponseResultModel
  message?: any
}

export interface FeedbackErrorAPIResponseModel {
  Message: string
}
export interface ResearchAssistantDataModel {
  id: string
  firstName: string
  lastName: string
  username: string
  email: string
  phone: string
  pin: string
  profilePhoto: string
}

export interface SiteTarget {
  gender: TargetGender
  minAge: number
  maxAge: number
  count: number
  stratification: Stratification
}

export interface Stratification {
  label: string
  operator: Operator
  surveyField: string
  value: string
}

// Get Survey Data Model
export interface ProjectSiteModel {
  id: string
  name: string
  location: string
  note: string
  researchAssistants: ResearchAssistantDataModel[]
  events?: EventDataModel[]
  targets?: SiteTarget[] | null
}

export interface EventDataModel {
  name: string
  date: string
}
export interface ProjectDataModel {
  id: string
  name: string
  sites?: ProjectSiteModel[]
  events?: EventDataModel[]
  specialEvents?: SpecialEventDataModel[]
  survey?: SurveyDataModel
  shopId?: number
}

export interface GrogContainerModel {
  name: string
  displayNamePlural: string
  localDisplayName: string
  localDisplayNamePlural: string
  capacity: number
  displayCapacity: string
  image: string
  fillMaskImage: string
  carouselImage: string
  opaque: boolean
  topBorder: number
  bottomBorder: number
}

export interface GrogAudioFragmentModel {
  name: string
  voices: genericObject
}

export interface GrogProductModel {
  name: string
  capacity: number
  displayCapacity: string
  image: string
  subContainer: number
  stepsPerSubContainer: number
  hiddenContainers: GrogContainerModel[]
}

export interface GrogBrandModel {
  id: number
  name: string
  alcoholPercent: string
  colour: string
  image: string
  products: GrogProductModel[]
}

export interface GrogSubTypeModel {
  id: number
  name: string
  alcoholPercent: string
  colour: string
  image: string
  hasBrand: boolean
  addFizzyDrink: boolean
  voices: genericObject
  containers: GrogContainerModel[]
  brands?: GrogBrandModel[]
  audioFragments: GrogAudioFragmentModel[]
}

export interface GrogTypeCategoryModel {
  id: number
  name: string
  colour: string
  image: string
  voices: genericObject
  subTypes?: GrogSubTypeModel[]
  audioFragments: GrogAudioFragmentModel[]
}

export interface NextScreenConditionModel {
  operator: Operator
  value: string
  nextScreenId: string
}

export interface NextScreenDataModel {
  isConditional?: boolean
  conditions?: NextScreenConditionModel[]
  nextScreenId?: string
}

export interface SurveyVoiceModel {
  label: string
  value: string
}

export interface FollowUpQuestionConditionModel {
  operator: Operator
  value: string
}

export interface FollowUpQuestionModel {
  id?: string
  isActive?: boolean
  conditions?: FollowUpQuestionConditionModel[]
  surveyField?: string
  data: any
  voices?: genericObject | null
  additionalQuestions: FollowUpQuestionModel[]
}

export interface DisplayScreenConditionAndModel {
  screenSurveyField: string
  operator: Operator
  value: string
}

export interface DisplayScreenConditionModel {
  screenSurveyField: string
  operator: Operator
  value: string
  and?: DisplayScreenConditionAndModel[] | null
}

export interface SpecialEventModel {
  eventId: string
  id: string
  name: string
  voices?: genericObject | null
}

export interface ShortCodeModel {
  shortcode: string
  surveyField: string
}

export interface ColourInfo {
  primaryColour: string
  secondaryColour: string
}

export type SurveyDataSectionModel = {
  id: string
  name?: string
} & ColourInfo

export interface SurveyDataScreenModel {
  id: string
  surveyField: string
  section: SurveyDataSectionModel
  helpText?: string
  voices?: genericObject | null
  data: any
  nextScreen?: NextScreenDataModel
  displayScreenCondition?: DisplayScreenConditionModel[] | null
  specialEventsVoices?: {
    isActive?: boolean
    events: SpecialEventModel[]
  }
  shortcodes?: ShortCodeModel[]
  surveyEnd?: boolean
  backButtonDisabled: boolean
}
export interface SurveyDataModel {
  id: string
  name: string
  voiceOver: SurveyVoiceModel[]
  screens: SurveyDataScreenModel[]
  isEnableSLK?: boolean
}

interface SLKEntry {
  projectId: string
  slkList: string[]
}

export type SLKResponseModel = SLKEntry[]

export interface GeoLocation {
  locality: string
  postCode: string
  state: string
}

export interface Contacts {
  name?: string
  phone?: string
  image?: string
  email?: string
}
export interface UseAPIHookFetchDataModel {
  id: string
  name: string
  projects: ProjectDataModel[]
  geoLocations?: GeoLocation[]
  contacts?: Contacts[]
}

export interface ServiceAPIResponseModel {
  data?: UseAPIHookFetchDataModel | null
  message?: string | null
  success?: boolean
}

export interface LocationAPIResponseModel {
  data?: GeoLocation[] | null
}

export interface GrogShopDataModel {
  Id: number
  Name: string
  typesCategories?: Array<GrogTypeCategoryModel | GrogSubTypeModel>
}
export interface SpecialEventDataModel {
  name: string
  id: string
  date: string
}
export interface GrogShopAPIResponseModel {
  data?: GrogShopDataModel[]
  message?: string | null
  success: boolean
}

export interface ForgotPinAPIResponseModel {
  data: string | null
  message: string
  success: boolean
}

export interface UseAPIHookStateModel {
  data?: SurveyResponse
  login: (data: UseAPIHookLoginDataModel) => Promise<LoginAPIResponseModel>
  fetchSurveyData: (id: string) => Promise<ServiceAPIResponseModel>
  fetchLocationData: () => Promise<LocationAPIResponseModel>
  fetchGrogShopData: (id: string) => Promise<GrogShopAPIResponseModel>
  handleSurveySubmit: (
    id: string,
    data: SurveyResponse[]
  ) => Promise<SubmissionAPIResponseModel>
  fetchTargets: (
    data: ProjectSiteTargetCollectionModel[]
  ) => Promise<TargetAPIResponseModel>
  handleFeedbackSubmit: (
    data: DailyFeedbackModel
  ) => Promise<number | FeedbackErrorAPIResponseModel>
  handleForgotPinSubmit: (data: string) => Promise<ForgotPinAPIResponseModel>
  handleSLKValidation: (data: string) => Promise<boolean>
  fetchSLKListData: (
    serviceId?: string,
    projectId?: string
  ) => Promise<SLKResponseModel>
}

export interface DailyFeedbackModel {
  Date: string
  ProjectId: string
  SiteId: string
  CommentConfusing?: string
  CommentFun?: string
  CommentHelp?: string
  CommentInteresting?: string
  CommentThink?: string
  CommentTooLong?: string
  CommentNotAgree?: string
  EngageComment?: string
  EngageEaseOfUse?: string
  EngageHappiness?: string
  EngageHelp?: string
  OtherComments?: string
  ProblemAudio?: string
  ProblemHardToSee?: string
  ProblemStoppedWorking?: string
  ProblemWrongLanguage?: string
  RaId?: string
  SerivceId?: string
}

export interface TargetCollection extends SiteTarget {
  numberOfCollection?: number
}

export interface SiteTargetCollectionModel {
  id: string
  name: string
  numberOfCollection?: number
  targets?: TargetCollection[] | null
}

export interface ProjectSiteTargetCollectionModel {
  id: string
  name: string
  sites?: SiteTargetCollectionModel[] | null
}

export interface TargetAPIResponseModel {
  projectSiteTargets?: ProjectSiteTargetCollectionModel[] | null
  error?: string
}

export interface SyncSurveysAPIDataModel {
  serviceId?: string
  surveyResponses?: SurveyResponse[]
  projectSiteTargets?: ProjectSiteTargetCollectionModel[] | null
}
