import React, { Dispatch } from 'react'
import { ProjectSiteSelectProps } from './ProjectSiteSelect.model'
import classnames from 'classnames'
import { Select } from 'components/Select/Select.component'
import { updateSelectedProjectSite } from 'store/reducer'
import { useDispatch } from 'react-redux'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'

/**
 * A Select combo containing a project selection and a site selection that depends on the selected project value.
 *
 * @param selectedProjectId The selected project id.
 * @param selectedSiteId The selected site id.
 * @param setSelectedProjectId A `set` function to update the `selectedProjectId` state to a different value and trigger a re-render.
 * @param setSelectedSiteId A `set` function to update the `selectedSiteId` state to a different value and trigger a re-render.
 * @param projects The project list of the current service to which an RA has access.
 * @param additionalStylingClasses Any additional classes used to style the `Select` component.
 * @param borderType The border type of the `Select` component.
 * @param style CSS style to change the `Select` look & feel.
 *
 * @return A Select combo component which renders a project selection and a site selection
 * used in the initialisation and the RA console of the app.
 */
export const ProjectSiteSelect: React.FC<ProjectSiteSelectProps> = ({
  selectedProjectId,
  selectedSiteId,
  setSelectedProjectId,
  setSelectedSiteId,
  projects,
  additionalStylingClasses,
  borderType,
  style
}) => {
  const dispatch: Dispatch<any> = useDispatch()

  const { generateProjectOptions, generateSiteOptions } = generateOptions()

  const handleSelectionChange = (newProjectId: string, newSiteId: string) => {
    dispatch(
      updateSelectedProjectSite({ projectId: newProjectId, siteId: newSiteId })
    )
    setSelectedProjectId(newProjectId)
    setSelectedSiteId(newSiteId)
  }

  return (
    <>
      <Select
        className={classnames(additionalStylingClasses)}
        options={generateProjectOptions(projects)}
        placeholder="Select Project"
        selectProps={{
          defaultValue: selectedProjectId,
          value: selectedProjectId,
          inputProps: {
            'aria-label': 'Select Project'
          }
        }}
        setValue={(value) => {
          handleSelectionChange(value, '')
        }}
        borderType={borderType}
        style={style}
      />
      <Select
        className={classnames(additionalStylingClasses)}
        options={generateSiteOptions(projects, selectedProjectId)}
        placeholder="Select Site"
        selectProps={{
          defaultValue: selectedSiteId,
          value: selectedSiteId,
          inputProps: {
            'aria-label': 'Select Site'
          }
        }}
        setValue={(value) => {
          handleSelectionChange(selectedProjectId, value)
        }}
        borderType={borderType}
        style={style}
      />
    </>
  )
}
