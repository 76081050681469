import React, { useState } from 'react'
import { Screen } from 'components/Screen/Screen.component'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { TipsScreenOption, TipsScreenPropsModel } from './TipsScreen.model'
import { SectionProgress } from 'components/SectionProgress/SectionProgress.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { SectionOption } from 'components/SectionProgress/SectionProgress.model'
import { useColourPicker } from 'context/ColourContext'
import { SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import styles from './TipsScreen.module.scss'
import { UsingTypes } from 'shared/constants/Constants.d'
import { updateOutroUserJourney } from 'store/reducer'

export const TipsScreenComponent: React.FC<TipsScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    headerProps,
    screenData,
    footerProps,
    shortcodes,
    surveyData,
    outroUserJourney
  } = props

  const completedOutroSections = outroUserJourney
    .filter((item) => item.completed)
    .map((j) => j.outroSection.toString())

  const dispatch = useDispatch()

  const { primaryColour, secondaryColour } = useColourPicker()

  const { getNextScreenId } = matchCondition()

  const { title, subtitle, onlyShowUser, currentOutroSection, options } =
    screenData

  const [nextScreenId, setNextScreenId] = useState<string>()
  const [nextOutroSection, setNextOutroSection] = useState<string>()

  const currOutroSection = Object.keys(UsingTypes).includes(currentOutroSection)
    ? UsingTypes[currentOutroSection as keyof typeof UsingTypes]
    : null

  const defineUsing = (option: TipsScreenOption, defaultScreenId?: string) => {
    if (surveyData && option.dependingScreen) {
      const dependingScreenValues = surveyData[
        option.dependingScreen
      ] as Array<string> | null
      if (
        dependingScreenValues &&
        dependingScreenValues.includes(option.value)
      ) {
        return {
          using: true,
          screenId: option.user
        }
      } else {
        return {
          using: false,
          screenId: option.nonUser
        }
      }
    }
    return {
      using: false,
      screenId: option.nonUser || defaultScreenId
    }
  }

  const formatOptions = () => {
    const defaultScreenId = getNextScreenId(
      undefined,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    const sectionOptions = options.map((option) => {
      const usingStatus = defineUsing(option, defaultScreenId)
      const isViewed =
        completedOutroSections.includes(option.value) ||
        currentOutroSection === option.value

      // option.dependingScreen is only false for buttons to
      // navigate away from tips (no tips button). This button
      // should be filled using primaryColour when unselected
      // and secondaryColour when selected (GitHub #510)
      const colours = option.dependingScreen
        ? undefined
        : { fill: primaryColour, fillOnSelect: secondaryColour }

      return {
        label: option.label,
        value: option.value,
        icon: usingStatus.using ? option.image : undefined,
        screenId: usingStatus.screenId,
        id: option.id,
        voices: option.voices,
        disabled: isViewed,
        completed: isViewed,
        highlighted: usingStatus.using,
        ...colours
      } as SectionOption
    })

    if (onlyShowUser) {
      // Show the sections containing the grog, tobacco & drugs that the user is using
      return sectionOptions.filter((sectionOption) => sectionOption.highlighted)
    }

    return sectionOptions
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (currOutroSection) {
      updatedFooterProps.onBackwardTransition = () => {
        // Pass a state update function when the Back button on an Outro Tips screen is clicked
        dispatch(
          updateOutroUserJourney({
            actionType: 'moving-backward',
            data: {
              currOutroSection: currOutroSection
            }
          })
        )
      }
    }

    if (!nextScreenId) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        undefined,
        nextScreenId
      )
      if (nextOutroSection) {
        const selectedOutroSection = Object.keys(UsingTypes).includes(
          nextOutroSection
        )
          ? UsingTypes[nextOutroSection as keyof typeof UsingTypes]
          : null

        updatedFooterProps.onForwardTransition = () => {
          // Pass a state update function when the Next button on an Outro Tips screen is clicked
          dispatch(
            updateOutroUserJourney({
              actionType: 'moving-forward',
              data: {
                currOutroSection: currOutroSection,
                nextOutroSection: selectedOutroSection
              }
            })
          )
        }
      }
    }

    return updatedFooterProps
  }

  return (
    <div className={`drug-app-screen ${styles.tipsscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['tipsscreen-container']}>
          <SectionProgress
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle || undefined)}
            options={formatOptions()}
            click={true}
            setOptionScreenId={(screenId) =>
              setNextScreenId(screenId ?? undefined)
            }
            setOptionValue={(value) => setNextOutroSection(value ?? undefined)}
          />
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  surveyData: state.surveyData,
  outroUserJourney: state.outroUserJourney ?? []
})

export const TipsScreen = connect(mapStateToProps)(TipsScreenComponent)
