import React, { useState, useEffect, useId } from 'react'
import { AutoCompletePropsModel } from './AutoComplete.model'
import styles from './AutoComplete.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export const AutoComplete = <T = string,>({
  className,
  dataList,
  inputOption,
  setValue,
  validateValue,
  textFieldProps,
  defaultValue = '',
  freeEntry,
  style
}: AutoCompletePropsModel<T>) => {
  const id = useId()
  const [inputValue, setInputValue] = useState<string>(defaultValue)

  const defaultValidation = (value: string) => {
    if (inputOption.required && value === '') {
      return 'Input is required'
    }
    return undefined
  }

  const handleValueValidation = (value: string) => {
    const error = validateValue
      ? validateValue(value)
      : defaultValidation(value)
    setValue(value, error)
  }

  // If defaultValue changes, update the inputValue
  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  return (
    <div className={classnames(className, styles.input)}>
      <div style={{ position: 'relative' }}>
        <Border type={BorderType.Select2} fill={style?.backgroundColor} />
        <label id={`label${id}`} htmlFor={id}>
          {inputOption.label}
        </label>
        <Autocomplete
          id={id}
          options={dataList}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
            handleValueValidation(newInputValue)
          }}
          freeSolo={freeEntry}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={`${inputOption.label ? inputOption.label : ''}${
                inputOption.required ? ' (required)' : ''
              }`}
              {...textFieldProps}
            />
          )}
        />
      </div>
    </div>
  )
}
