import { SnackbarProps } from './Snackbar.model'
import {
  Alert as MuiAlert,
  Snackbar as MuiSnackbar,
  SnackbarCloseReason
} from '@mui/material'
import React from 'react'
import classnames from 'classnames'
import styles from './Snackbar.module.scss'

export const Snackbar: React.FC<SnackbarProps> = ({
  message,
  isError,
  open,
  setOpen
}) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <MuiAlert
        className={classnames(styles.snackbar__alert, {
          [styles['snackbar__alert--error']]: isError,
          [styles['snackbar__alert--success']]: !isError
        })}
        onClose={handleClose}
        severity={isError ? 'error' : 'success'}
        variant="filled"
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  )
}
