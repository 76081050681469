import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import styles from './ProgressScreen.module.scss'
import { SideMenu } from 'components/SideMenu/SideMenu.component'
import { AnalysisModel, RADashboardMenu } from '../index.model'
import { useNavigate } from 'react-router-dom'
import { SurveyState } from 'store/type'
import { ProgressScreenPropsModel } from './ProgressScreen.model'
import { Dispatch } from 'redux'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { colors } from 'shared/theme/theme'
import Box from '@mui/material/Box'
import { Button } from 'components/Button/Button.component'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import { BorderType } from 'components/Border/Border.model'
import { IconType } from 'components/Icons/Icon.model'
import classnames from 'classnames'
import { syncSurveysAPI } from 'store/actionCreators'
import { Card } from 'components/Card/Card.component'
import { TargetGender } from 'shared/utils/raDashboardCalculation/raDashboardCalculation.model'
import { mapProjectSiteTargetCollection } from 'store/mapper'
import { RaTargetsType } from 'shared/constants/Constants.d'
import { ProjectSiteSelect } from 'components/ProjectSiteSelect/ProjectSiteSelect.component'
import { Snackbar } from 'components/Snackbar/Snackbar.component'
import { SUCCESSFULLY_SYNCED } from 'shared/constants/messages/Messages.d'

const ProgressScreenComponent: React.FC<ProgressScreenPropsModel> = (props) => {
  const {
    offline,
    serviceId,
    syncRounds,
    syncStatus,
    projects,
    projectSiteTargets,
    projectId: selectedProjectId,
    siteId: selectedSiteId,
    surveyResponses
  } = props

  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const [projectId, setProjectId] = useState<string>(selectedProjectId)
  const [siteId, setSiteId] = useState<string>(selectedSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  // Map a projectSiteTargets instance from project if the state.projectSiteTargets does not exist
  const projectSiteTargetCollection =
    projectSiteTargets.length > 0
      ? projectSiteTargets
      : mapProjectSiteTargetCollection(projects)

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'rgba(51, 51, 51, .2)'
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.capePalliser
    }
  }))

  const { generateProjectOptions, generateSiteOptions } = generateOptions()
  const { calcTotalResponses, generateTargetData, calcTargetSurveys } =
    RADashCalc()

  const totalSurveys = calcTotalResponses(projectId, siteId)
  const targetSurveys = calcTargetSurveys(projectId, siteId)
  const totalCompletedSurveys = totalSurveys.filter(
    (survey) =>
      !survey.metadata?.flaggedComingBack &&
      !survey.metadata?.flaggedDeleted &&
      !survey.metadata?.flaggedIncomplete
  )

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const handleSyncAPI = () => {
    setIsSync(true)
    // Create a clean projectSiteTargetsRequest without the numbers of collections populated
    const projectSiteTargetsRequest = mapProjectSiteTargetCollection(projects)
    dispatch(
      syncSurveysAPI({
        serviceId,
        surveyResponses,
        projectSiteTargets: projectSiteTargetsRequest
      })
    )

    setOpenSnackbar(true)
  }

  /**
   * Overall progress: Tracks the total synced completed surveys against the total target. (GitHub #508)
   */
  const getSiteOverallCollection = () => {
    const selectedProject = projectSiteTargetCollection.find(
      (p) => p.id === projectId
    )
    const site = selectedProject?.sites?.find((s) => s.id === siteId)
    if (selectedProject && site) {
      const numOfSyncedCompletedSurveys: number = site.numberOfCollection ?? 0
      return numOfSyncedCompletedSurveys
    }
    return 0
  }

  const generateTargets = () => {
    const selectedProject = projectSiteTargetCollection.find(
      (p) => p.id === projectId
    )
    const site = selectedProject?.sites?.find((s) => s.id === siteId)
    const targets = site?.targets
    const results = generateTargetData(targets, RaTargetsType.OverallProgress)

    return (
      results &&
      results.map((item, index) => (
        <Box key={`stratification-${index}`}>
          <p key={`p-${index}`}>{item.value}</p>
          <div className={styles['progressscreen-analysis']}>
            {item.data &&
              item.data.map((currentData, _currentDataIndex) =>
                generateAnalysis(
                  currentData.gender,
                  currentData.data,
                  `stratification-${index}`
                )
              )}
          </div>
        </Box>
      ))
    )
  }

  const generateAnalysis = (
    title: string,
    values: AnalysisModel[],
    parentLabel: string
  ) => {
    return (
      <div className={styles['progressscreen-analysis-section']}>
        <p>{title}</p>
        <div className={styles['progressscreen-analysis-section-wrapper']}>
          {values.map((item, index) => (
            <Box
              key={`${parentLabel}-${title}-${index}`}
              sx={{ width: '100%' }}
            >
              <label
                id={`analysis-label-${index}`}
                className={styles['progressscreen-analysis-section-labels']}
              >
                <span>{item.label}</span>
                <span>{`${item.quantity} / ${item.count}`}</span>
              </label>
              <BorderLinearProgress
                className={styles['progressscreen-analysis-progress']}
                variant="determinate"
                value={item.count > 0 ? (item.quantity / item.count) * 100 : 0}
                aria-labelledby={`analysis-label-${index}`}
              />
            </Box>
          ))}
        </div>
      </div>
    )
  }

  const calcTargetNumber = (
    total: boolean,
    age?: number[],
    gender?: TargetGender
  ) => {
    if (targetSurveys) {
      let totalNum = 0
      let filteredNum = 0

      targetSurveys.forEach((target) => {
        totalNum += target.count

        if (
          age &&
          gender &&
          age[0] <= target.minAge &&
          age[1] >= target.maxAge &&
          gender === target.gender
        ) {
          filteredNum += target.count
        }
      })

      if (total) {
        return totalNum
      } else {
        return filteredNum
      }
    }
    return 0
  }

  useEffect(() => {
    setIsSync(false)
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.progressscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[2].value}
        setValue={handleMenuChange}
      />
      <main className={styles['progressscreen-content']}>
        <h1
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none'
          }}
        >
          Overall Progress
        </h1>
        <div className={styles['progressscreen-row1']}>
          <Box component="form" className={styles['progressscreen-row1-form']}>
            <ProjectSiteSelect
              selectedProjectId={projectId}
              selectedSiteId={siteId}
              setSelectedProjectId={setProjectId}
              setSelectedSiteId={setSiteId}
              projects={projects}
              additionalStylingClasses={styles['progressscreen-select']}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['progressscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['progressscreen-row1-syncButton'], {
                [styles['progressscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
          {!isSync && (
            <Snackbar
              message={
                syncStatus && syncStatus.isSynced
                  ? SUCCESSFULLY_SYNCED
                  : syncStatus?.errorMessage || ''
              }
              isError={(syncStatus && !syncStatus.isSynced) || false}
              open={openSnackbar}
              setOpen={setOpenSnackbar}
            ></Snackbar>
          )}
        </div>
        <div className={styles['progressscreen-row2']}>
          <Card
            className={styles['progressscreen-card']}
            title={`${generateProjectOptions(projects, projectId)[0].label} / ${
              generateSiteOptions(projects, projectId)[0].label
            }`}
            titleColor={colors.breakerBay}
            titleHeight={57}
            centerAlign={false}
          >
            <div
              className={classnames(
                styles['progressscreen-analysisWrapper'],
                styles['progressscreen-analysisWrapper--total']
              )}
            >
              <div className={styles['progressscreen-analysis-section']}>
                <div
                  className={styles['progressscreen-analysis-section-wrapper']}
                >
                  {siteId && siteId !== '' && (
                    <Box sx={{ width: '100%' }}>
                      <label
                        id="site-progress-label"
                        className={
                          styles['progressscreen-analysis-section-labels']
                        }
                      >
                        <span>People surveyed at this site overall</span>
                        <span
                          className={
                            styles['progressscreen-analysis-section-labels-big']
                          }
                        >
                          {`${getSiteOverallCollection()} / ${calcTargetNumber(
                            true
                          )}`}
                          <span>
                            {(
                              (getSiteOverallCollection() /
                                calcTargetNumber(true)) *
                              100
                            ).toFixed(1)}
                            %
                          </span>
                        </span>
                      </label>
                      <BorderLinearProgress
                        className={styles['progressscreen-analysis-progress']}
                        variant="determinate"
                        value={
                          (getSiteOverallCollection() /
                            calcTargetNumber(true)) *
                          100
                        }
                        style={{ height: 16 }}
                        aria-labelledby="site-progress-label"
                      />
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div className={styles['progressscreen-analysisWrapper']}>
              {generateTargets()}
            </div>
          </Card>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  serviceId: state.service?.id,
  syncRounds: state.syncRounds,
  syncStatus: state.syncStatus,
  projects: state.projects ?? [],
  projectSiteTargets: state.projectSiteTargets ?? [],
  projectId: state.selectedProjectSite?.projectId || '',
  siteId: state.selectedProjectSite?.siteId || '',
  surveyResponses: state.surveyResponses
})

export const ProgressScreen = connect(mapStateToProps)(ProgressScreenComponent)
