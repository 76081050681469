import React from 'react'
import styles from './DailyFeedback.module.scss'
import {
  DailyFeedbackPropsModel,
  weekday,
  months,
  feedbackOptions
} from './DailyFeedback.model'
import classnames from 'classnames'
import { Select } from 'components/Select/Select.component'
import { Text } from 'components/Text/Text.component'
import { ordinal_suffix_of } from 'shared/utils/ordinalSuffix/ordinalSuffix'
import { ProjectDataModel } from 'api/client.model'
import { Feedback } from 'components/Feedback/Feedback.component'
import { colors } from 'shared/theme/theme'
import { Alert } from '../Alert/Alert.component'
import { Button } from '../Button/Button.component'

export const DailyFeedback: React.FC<DailyFeedbackPropsModel> = (props) => {
  const {
    projects,
    feedbackState,
    setFeedbackState,
    feedbackSubmitting,
    feedbackDate,
    setFeedbackDate,
    feedbackSite,
    setFeedbackSite,
    feedbackError,
    feedbackProject,
    setFeedbackProject,
    btnCallBack
  } = props

  const generateLabel = (index: number, calculatedDate: Date) => {
    if (index === 0) {
      return 'Today'
    } else if (index === 1) {
      return 'Yesterday'
    } else {
      return `${weekday[calculatedDate.getDay()]} ${ordinal_suffix_of(
        calculatedDate.getDate()
      )} ${months[calculatedDate.getMonth()]}`
    }
  }

  function getPastDates(daysToSubtract: number) {
    const now = new Date()

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - daysToSubtract
    )
  }

  const generateDates = () => {
    const datesArray = []

    for (let i = 0; i < 7; i++) {
      const calculatedDate = getPastDates(i)

      const weekDate = {
        label: generateLabel(i, calculatedDate),
        value: calculatedDate.toDateString()
      }

      datesArray.push(weekDate)
    }

    return datesArray
  }

  generateDates()

  const handleChange = (name: string, value: string) => {
    setFeedbackState({
      ...feedbackState,
      [name]: value
    })
  }

  const projectOptions =
    projects &&
    projects.length !== 0 &&
    projects.map((project: ProjectDataModel) => ({
      'label': project.name,
      'value': project.id
    }))

  const siteOptions = () => {
    const selectedProject = projects?.filter(
      (project: ProjectDataModel) => project.id === feedbackProject
    )
    return (
      selectedProject &&
      selectedProject.length !== 0 &&
      selectedProject[0].sites?.map((site) => ({
        'label': site.name,
        'value': site.id
      }))
    )
  }

  return (
    <div className={styles['feedback-container']}>
      {/* Heading */}
      <div className={styles['feedback-heading']}>
        <p>
          Thanks for your work!
          <br />
          The aim of this survey is to collect your daily thoughts on what
          worked, what did not seem to work and what could be done better.
          <br />
          <br /> Any observations you make of people completing the survey app
          are valuable.
        </p>
      </div>
      <hr />

      {/* Date */}
      <div className={styles['feedback-selectOptions']}>
        <div className={styles['feedback-selectLabels']}>
          <label id="feedback-question-1">
            1. Date<span style={{ color: colors.chestnutRose }}>*</span>
          </label>
        </div>
        <div>
          <Select
            className={styles['feedback-dropdown--half']}
            placeholder="Choose"
            setValue={setFeedbackDate}
            options={generateDates()}
            selectProps={{
              labelId: 'feedback-question-1'
            }}
          />
        </div>
      </div>
      <hr />

      {/* Project */}
      <div className={styles['feedback-selectOptions']}>
        <div className={styles['feedback-selectLabels']}>
          <label id="feedback-question-2">
            2. Project<span style={{ color: colors.chestnutRose }}>*</span>
          </label>
        </div>
        <div>
          <Select
            className={styles['feedback-dropdown--full']}
            setValue={setFeedbackProject}
            placeholder="Select Project"
            options={projectOptions || []}
            selectProps={{
              labelId: 'feedback-question-2'
            }}
          />
        </div>
      </div>

      {/* Site */}
      <div className={styles['feedback-selectOptions']}>
        <div className={styles['feedback-selectLabels']}>
          <label id="feedback-question-3">
            3. Site<span style={{ color: colors.chestnutRose }}>*</span>
          </label>
        </div>
        <div>
          <Select
            className={styles['feedback-dropdown--full']}
            setValue={setFeedbackSite}
            placeholder="Select Site"
            options={siteOptions() || []}
            selectProps={{
              labelId: 'feedback-question-3'
            }}
          />
        </div>
      </div>
      <hr />

      <div className={styles['feedback-section']}>
        <p className={styles['margin-small']}>
          4. Thinking about how people seemed to engage with the tablet app
          today…
        </p>

        <div className={styles['feedback-questions']}>
          <p>a. Did people seem to enjoy using the app or not? </p>
          <Feedback
            key="enjoyment-feedback"
            name="enjoyment"
            className={styles['feedback-options']}
            options={feedbackOptions}
            setValue={(value) => handleChange('EngageHappiness', value)}
          />
        </div>

        <div className={styles['feedback-questions']}>
          <p>
            b. How hard did people find it to use the app (e.g. did it seem OK
            for people to go from question to question or did they look
            confused)?
          </p>
          <Feedback
            key="difficulty-feedback"
            name="difficulty"
            className={styles['feedback-options']}
            options={feedbackOptions}
            setValue={(value) => handleChange('EngageEaseOfUse', value)}
          />
        </div>

        <div className={styles['feedback-questions']}>
          <div>
            <label htmlFor="feedback-question-4-c">
              c. How many people had to ask you for help with using the app
              today, once they had started the survey?
            </label>
          </div>
          <div className={styles['feedback-4c']}>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-4-c',
                required: true,
                fullWidth: true,
                type: 'number',
                placeholder: 'please enter a number',
                inputProps: { min: 0 }
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  EngageHelp: value
                })
              }
            />
          </div>
        </div>

        <div className={styles['feedback-questions']}>
          <div>
            <p>
              d. How many people after finishing the survey made these sorts of
              comments…
            </p>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part1">
                It got me thinking about my drinking
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part1',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentThink: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part2">
                Where can people get help for their drinking?
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part2',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentHelp: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part3">That was fun</label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part3',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentFun: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part4">
                That was hard or confusing
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part4',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentConfusing: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part5">
                That was interesting
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part5',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentInteresting: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part6">
                It was too long
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part6',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentTooLong: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-inputOption']}>
            <div>
              <label htmlFor="feedback-question-4-d-part7">
                I didn&apos;t agree with my feedback
              </label>
            </div>
            <div>
              <Text
                className={classnames(styles['feedback-numberInput'])}
                textFieldProps={{
                  id: 'feedback-question-4-d-part7',
                  required: true,
                  fullWidth: true,
                  type: 'number',
                  placeholder: 'please enter a number',
                  inputProps: { min: 0 }
                }}
                setValue={(value: string) =>
                  setFeedbackState({
                    ...feedbackState,
                    CommentNotAgree: value
                  })
                }
              />
            </div>
          </div>
          <div className={styles['feedback-questions']}>
            <label htmlFor="feedback-question-4-d-part8">
              Any other comments, thinking about how people seemed to engage
              with the tablet app today
            </label>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-4-d-part8',
                required: true,
                fullWidth: true,
                type: 'text',
                inputProps: { min: 0 },
                multiline: true,
                minRows: 3
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  EngageComment: value
                })
              }
            />
          </div>
        </div>
      </div>
      <hr />

      <div className={styles['feedback-questions']}>
        <div>
          <p>
            5. How many times did you have any of the following problems with
            the app today?
          </p>
        </div>
        <div className={styles['feedback-inputOption']}>
          <div>
            <label htmlFor="feedback-question-5-part1">
              It stopped working
            </label>
          </div>
          <div>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-5-part1',
                required: true,
                fullWidth: true,
                type: 'number',
                placeholder: 'please enter a number',
                inputProps: { min: 0 }
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  ProblemStoppedWorking: value
                })
              }
            />
          </div>
        </div>
        <div className={styles['feedback-inputOption']}>
          <div>
            <label htmlFor="feedback-question-5-part2">
              The screen was hard to see
            </label>
          </div>
          <div>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-5-part2',
                required: true,
                fullWidth: true,
                type: 'number',
                placeholder: 'please enter a number',
                inputProps: { min: 0 }
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  ProblemHardToSee: value
                })
              }
            />
          </div>
        </div>
        <div className={styles['feedback-inputOption']}>
          <div>
            <label htmlFor="feedback-question-5-part3">
              The voice was hard to hear
            </label>
          </div>
          <div>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-5-part3',
                required: true,
                fullWidth: true,
                type: 'number',
                placeholder: 'please enter a number',
                inputProps: { min: 0 }
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  ProblemAudio: value
                })
              }
            />
          </div>
        </div>
        <div className={styles['feedback-inputOption']}>
          <div>
            <label htmlFor="feedback-question-5-part4">
              The wrong language came up
            </label>
          </div>
          <div>
            <Text
              className={classnames(styles['feedback-numberInput'])}
              textFieldProps={{
                id: 'feedback-question-5-part4',
                required: true,
                fullWidth: true,
                type: 'number',
                placeholder: 'please enter a number',
                inputProps: { min: 0 }
              }}
              setValue={(value: string) =>
                setFeedbackState({
                  ...feedbackState,
                  ProblemWrongLanguage: value
                })
              }
            />
          </div>
        </div>
        <div>
          <label htmlFor="feedback-question-5-part5">
            Any other comments:{' '}
          </label>
          <Text
            className={styles['feedback-otherComments']}
            textFieldProps={{
              id: 'feedback-question-5-part5',
              required: true,
              fullWidth: true,
              type: 'text',
              inputProps: { min: 0 },
              multiline: true,
              minRows: 3
            }}
            setValue={(value: string) =>
              setFeedbackState({
                ...feedbackState,
                OtherComments: value
              })
            }
          />
        </div>
      </div>
      <hr />
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          variation="primary"
          width="l"
          className={styles['dailyFeedbackModal-cta']}
          onClick={btnCallBack}
          disabled={
            feedbackSubmitting ||
            !feedbackState ||
            !feedbackDate ||
            !feedbackProject ||
            !feedbackSite
          }
        >
          {feedbackError && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={feedbackError}
              backgroundColor={colors.crimson}
            />
          )}
          {feedbackSubmitting ? 'Submitting ...' : 'Submit'}
        </Button>
      </div>
    </div>
  )
}
